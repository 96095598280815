<template>
  <ListPageWithCreateButton
    createRoutName="createAllocation"
    :showCreateBtn="isCurrentAcademicYear"
    :createBtnName="$t('grading.add_allocation')">
    <template slot="leftSideButtonContent">
      <!-- <p class="" :class="$vs.rtl ? 'text-right pl-2' : 'text-left pr-2'">
        {{
          $t(
            "grading.If_not_applied_any_assessments_allocations_then_Schoolia_by_default_is_applying_the_grades_on_averages_basis_of_the_final_exam"
          )
        }}
      </p> -->
    </template>
    <template slot="listDataContent">
      <transition-group class="list-transition" name="list-enter-up" tag="ul">
        <template>
          <li
            class="mb-10"
            v-for="(val, i) in allocations"
            :key="val.id"
            :style="[{transitionDelay: i * 0.1 + 's'}]">
            <allocation-card
              :allocation="val"
              @changeStatus="confirmChangeStatus"></allocation-card>
          </li>
        </template>
      </transition-group>

      <template v-if="!allocations.length">
        <vx-card :showLoading="loading" v-if="!loading">
          <p class="text-center">
            <span>{{ $t('basic.no_data_available') }}</span>
          </p>
        </vx-card>
      </template>

      <template v-if="allocations.length && totalPages > 1">
        <vs-pagination
          :total="totalPages"
          :max="countPerPage"
          v-model="currentPage"
          class="mt-6" />
      </template>
    </template>
    <template slot="rightSide">
      <FilterBar @reloadData="reloadData" />
    </template>
  </ListPageWithCreateButton>
</template>
<script>
import allocationCard from './components/allocationCard.vue'
import {commonDataMixin} from '@/mixins.js'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
import FilterBar from './components/FilterBar'

const AllocationsRepository = RepositoryFactory.get(
  'GradingModule',
  'allocations'
)

export default {
  name: 'allocations',
  mixins: [commonDataMixin],

  components: {
    allocationCard,
    FilterBar
  },
  data: () => ({
    loading: false,
    allocations: [],
    currentPage: 1
  }),
  watch: {
    currentPage() {
      this.getAllocations()
    }
  },
  computed: {
    isCurrentAcademicYear() {
      return Boolean(this.currentAcademicYear?._id)
    }
  },
  methods: {
    /////////////////////////
    // APIs
    ///////////////////////
    getAllocations(v = {}, page) {
      this.loading = true
      this.VSLoading()
      this.allocations = []

      AllocationsRepository.getAllAllocations(page || this.currentPage, v)
        .then((res) => {
          this.setAllocations(res.data)
        })
        // .catch(this.errMsg)
        .finally(() => {
          this.loading = false
          this.stopVSLoading()

          // TODO : Remove it after fix the integration
          // if (!this.allocations.length) this.setAllocations(staticData.index.data)
        })
    },
    changeStatus(data) {
      this.loading = true
      AllocationsRepository.changeAllocationStatus(data.id, data.isActive)
        .then(() => this.getAllocations())
        .catch(this.errMsg)
        .finally(() => {
          this.loading = false
        })
    },
    /////////////////////////
    // Set Data
    ///////////////////////
    setAllocations(data) {
      this.allocations = data.data
      this.setPagination(data)
    },
    ///////////////////////
    // Filter
    ///////////////////////
    reloadData(v) {
      this.getAllocations(v, 1)
    },
    ///////////////////////
    // Confirmation
    ///////////////////////
    confirmChangeStatus(data) {
      const newStatusName = data.isActive
        ? this.$t('status.activate')
        : this.$t('status.suspend')
      this.$vs.dialog({
        type: 'confirm',
        color: data.isActive ? 'success' : 'danger',
        title: `${this.$t('basic.confirm')} `,
        text: ` ${this.$t('basic.you_are_about_to')} ${newStatusName}`,
        accept: () => this.changeStatus(data),
        acceptText: `${newStatusName}`
      })
    }
  },
  created() {
    this.getAllocations()
  }
}
</script>
