<template>
  <sideBar
    :filters_prop="custom_filter"
    @refilter="setFilters"
    :loading="loadingFilter">
    <template slot="extra_content">
      <div class="w-full mb-10">
        <vs-input
          :label-placeholder="$t('form.search_by_name')"
          v-model="searchContent"
          class="w-full"
          name="name"
          icon="icon icon-search"
          icon-pack="feather"
          @change="setFilters" />
        <span class="text-sm text-danger" v-if="errors.first('email')">{{
          errors.first('name') | text_formatter
        }}</span>
      </div>
    </template>
  </sideBar>
</template>

<script>
import sideBar from '@/components/general/RoundFilter'
import {commonDataMixin} from '@/mixins.js'

export default {
  name: 'FilterBar',
  components: {sideBar},
  mixins: [commonDataMixin],
  data() {
    return {
      //////////////////////////
      // Filters
      /////////////////////////
      searchContent:'',
      sideFilters: {
        general_search: '',
        divisions: null,
        grades: null,
        status: null,
        sections: null,
        name: null,
        academic_year_id: null
      },
      subjects: [],
      schools: [],
      custom_filter: [
        {
          name: this.$t('basic.academic_year'),
          payload_name: 'academic_year_id',
          singleSeletable: true,
          by: []
        },
        {
          name: this.$t('basic.divisions'),
          payload_name: 'divisions',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.sections'),
          payload_name: 'sections',
          onChangeFireAction: true,
          by: []
        },
        {
          name: this.$t('basic.grades'),
          payload_name: 'grades',
          by: []
        },
        {
          name: this.$t('basic.status'),
          payload_name: 'status',
          singleSeletable: true,
          by: [
            {id: 'active', name: this.$t('status.active'), checked: false},
            {
              id: 'suspended',
              name: this.$t('status.suspended'),
              checked: false
            },
            {
              id: 'expired',
              name: this.$t('status.expired'),
              checked: false
            }
          ]
        }
      ],

      // Search
      searchContent: '',
      loadingFilter: false
    }
  },
  computed: {
    schoolID() {
      return this.authUser.school_id
    }
  },

  methods: {
    //////////////////////
    // Helpers
    /////////////////////
    setFilters(val) {
      this.sideFilters["general_search"] = this.searchContent;
      val.map((item) => {
        this.sideFilters[item.name] = item.checked
      })
      this.reloadData()
    },
    getCustomFilterEl(payload_name) {
      return this.custom_filter.find((e) => e.payload_name === payload_name)
    },
    byObj(el) {
      return {
        id: el.id || el._id,
        name: el.name,
        checked: false
      }
    },
    resetOptions(arrOfPayloadNames) {
      arrOfPayloadNames.forEach((payload_name) => {
        this.getCustomFilterEl(payload_name).by = []
      })
    },
    search() {
      this.sideFilters.name = this.searchContent
      if (this.searchContent.length >= 3 || !this.searchContent) {
        this.reloadData()
      }
    },
    reloadData() {
      this.$emit('reloadData', this.sideFilters)
    },
    //////////////////////
    // APIs
    /////////////////////
    getFiltersData() {
      this.loadingFilter = true
      this.getFilterData({schools: [this.schoolID]})
        .then((res) => {
          res.divisions.map((el) => {
            this.getCustomFilterEl('divisions').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getSections(chosen) {
      this.loadingFilter = true
      const data = {
        schools: [this.schoolID],
        divisions: chosen.divisions
      }
      this.resetOptions(['sections', 'grades'])

      this.getFilterData(data)
        .then((res) => {
          res.sections.map((el) => {
            this.getCustomFilterEl('sections').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getGrades(chosen) {
      this.loadingFilter = true

      this.resetOptions(['grades'])
      const data = {
        schools: [this.schoolID],
        divisions: chosen.divisions,
        sections: chosen.sections
      }
      this.getFilterData(data)
        .then((res) => {
          res.grades.map((el) => {
            this.getCustomFilterEl('grades').by.push(this.byObj(el))
          })
        })
        .catch(this.errMsg)
        .finally(() => {
          this.loadingFilter = false
        })
    },
    getAllAcademicYears() {
      this.getAllAcademic().then((res) => {
        res.forEach((el) => {
          this.getCustomFilterEl('academic_year_id').by.push(this.byObj(el))
        })
      })
    }
  },
  created() {
    this.getFiltersData()
    this.getAllAcademicYears()
    this.$root.$on('onChange:divisions', (v) => {
      this.getSections(v)
    })
    this.$root.$on('onChange:sections', (v) => {
      this.getGrades(v)
    })
  },
  beforeDestroy() {
    this.$root.$off('onChange:divisions')
    this.$root.$off('onChange:sections')
  }
}
</script>

<style scoped></style>
