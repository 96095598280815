<template>
  <grid-card-4>
    <template v-slot:actionButtons>
      <vs-dropdown
        class="actionButtons text-capitalize cursor-pointer"
        vs-custom-content
        vs-trigger-click>
        <vs-button color="#999" type="flat" icon="more_vert"></vs-button>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="goToEdit">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4 mr-1" />{{
              $t('basic.edit')
            }}</vs-dropdown-item
          >
          <!-- <vs-dropdown-item @click="goToShow">
            <feather-icon icon="EyeIcon" svgClasses="h-4 w-4 mr-1" />{{
              $t("basic.show")
            }}</vs-dropdown-item
          > -->
          <template v-if="!allocation.is_expired">
            <vs-dropdown-item
              @click="changeStatus(0)"
              v-if="allocation.is_active">
              <feather-icon icon="SlashIcon" svgClasses="h-4 w-4 mr-1" />{{
                $t('status.suspend')
              }}</vs-dropdown-item
            >
            <vs-dropdown-item @click="changeStatus(1)" v-else>
              <feather-icon icon="CheckIcon" svgClasses="h-4 w-4 mr-1" />{{
                $t('status.activate')
              }}</vs-dropdown-item
            >
          </template>
        </vs-dropdown-menu>
      </vs-dropdown>
    </template>
    <template v-slot:first>
      <h5>{{ allocation.name }}</h5>
    </template>
    <template v-slot:second>
      <p>
        <span>{{ allocationGrades }}</span>
      </p>
    </template>
    <template v-slot:third>
      <p class="flex items-center">
        <feather-icon icon="UserIcon" svgClasses="h-4 w-4 mr-1" />
        <span class="mx-2"> {{ allocation.user }} </span>
      </p>
    </template>

    <template v-slot:fourth>
      <p class="flex items-center justify-between">
        <span class="flex items-center">
          <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 mr-1" />
          <span class="mx-2 text-sm">
            {{ allocation.start_date }} <br />
            {{ allocation.end_date }}
          </span>
        </span>
        <template v-if="!allocation.is_expired">
          <span
            :class="`status--${
              allocation.is_active ? 'success' : 'warning'
            } mb-0 lg:ml-2 text-sm`"
            >{{
              allocation.is_active
                ? $t('status.active')
                : $t('status.suspended')
            }}</span
          >
        </template>
        <template v-else>
          <span :class="`status--danger mb-0 lg:ml-2 text-sm`">{{
            $t('status.expired_date')
          }}</span>
        </template>
      </p>
    </template>
  </grid-card-4>
</template>
<script>
export default {
  name: 'allocation-card',
  props: ['allocation'],
  computed: {
    allocationGrades() {
      const grades = this.allocation.grading_management
      return grades
        .map((e) => `${e.division_name} - ${e.section_name} - ${e.name}`)
        .join(',')
    }
  },
  methods: {
    goToEdit() {
      this.$router.push({
        name: 'editAllocation',
        params: {id: this.allocation.id}
      })
    },
    goToShow() {
      this.$router.push({
        name: 'showAllocation',
        params: {id: this.allocation.id}
      })
    },
    changeStatus(isActive) {
      this.$emit('changeStatus', {id: this.allocation.id, isActive})
    }
  }
}
</script>
